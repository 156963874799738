// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abuse-js": () => import("./../../../src/pages/abuse.js" /* webpackChunkName: "component---src-pages-abuse-js" */),
  "component---src-pages-bing-js": () => import("./../../../src/pages/bing.js" /* webpackChunkName: "component---src-pages-bing-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-terms-acceptable-use-js": () => import("./../../../src/pages/terms/acceptable-use.js" /* webpackChunkName: "component---src-pages-terms-acceptable-use-js" */),
  "component---src-pages-terms-arbitration-agreement-js": () => import("./../../../src/pages/terms/arbitration-agreement.js" /* webpackChunkName: "component---src-pages-terms-arbitration-agreement-js" */),
  "component---src-pages-terms-copyright-js": () => import("./../../../src/pages/terms/copyright.js" /* webpackChunkName: "component---src-pages-terms-copyright-js" */),
  "component---src-pages-terms-data-request-policy-js": () => import("./../../../src/pages/terms/data-request-policy.js" /* webpackChunkName: "component---src-pages-terms-data-request-policy-js" */),
  "component---src-pages-terms-dnrn-policy-js": () => import("./../../../src/pages/terms/dnrn-policy.js" /* webpackChunkName: "component---src-pages-terms-dnrn-policy-js" */),
  "component---src-pages-terms-domain-registration-js": () => import("./../../../src/pages/terms/domain-registration.js" /* webpackChunkName: "component---src-pages-terms-domain-registration-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-terms-professional-services-agreement-js": () => import("./../../../src/pages/terms/professional-services-agreement.js" /* webpackChunkName: "component---src-pages-terms-professional-services-agreement-js" */),
  "component---src-pages-terms-professional-services-agreement-previous-js": () => import("./../../../src/pages/terms/professional-services-agreement-previous.js" /* webpackChunkName: "component---src-pages-terms-professional-services-agreement-previous-js" */),
  "component---src-pages-terms-terms-of-service-js": () => import("./../../../src/pages/terms/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-terms-of-service-js" */),
  "component---src-pages-terms-trademark-disputes-js": () => import("./../../../src/pages/terms/trademark-disputes.js" /* webpackChunkName: "component---src-pages-terms-trademark-disputes-js" */)
}

